.custom-search .ant-input {
  text-align: center;
  padding: 12px;
  border-radius: 10px 10px 0 0;
  border-start-end-radius: 10px !important;
  border-color: #d9d9d9;
}

.custom-search .ant-input-search-button {
  background-color: #1f32ac; /* Blue button */
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.custom-search .ant-input-group {
  display: flex;
  flex-direction: column;
  border-radius: 10px 0px;
  overflow: hidden;
}

.custom-search .ant-input-group-addon {
  width: 100%;
}
